<style scoped>
.search {
    width: 30%;
}
@media only screen and (max-width: 600px) {
  .search {
    width: 100%;
  }
}

.form__label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  margin-left: 2rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(0rem);
}

.form__input {
  font-family: 'Roboto', sans-serif;
  color: #333;
  font-size: 1.2rem;
	margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}

.mt-10 {
    margin-top: 10px;
}
</style>
<template>
    <div v-loading.fullscreen.lock="loading">

        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8" align="right">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row card shadow border-0 p-5">
                <form role="form" @submit.prevent="addUser">
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mt-10">
                            <el-input placeholder="Овог (30 тэмдэг)" maxlength="30" required v-model="user.lastname" clearable > </el-input>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mt-10">
                            <el-input placeholder="Нэр (30 тэмдэг)" maxlength="30" required v-model="user.firstname" clearable > </el-input>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mt-10">
                            <el-input placeholder="Имэйл (50 тэмдэг)" required type="email" maxlength="50" v-model="user.email" clearable > </el-input>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mt-10">
                            <el-input placeholder="Утасны дугаар" maxlength="20" required pattern="[0-9]{8}" v-model="user.phone" clearable > </el-input>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mt-10">
                            <el-select v-model="user.city" filterable placeholder="Хот / Аймаг" style="width: 80%; margin-right: 10px;">
                                <el-option
                                v-for="(item, index) in city"
                                :key="index"
                                :label="item.cityname"
                                :value="item.id">
                                </el-option>
                            </el-select>
                            <el-button icon="el-icon-plus" @click="modals.city = true;" circle></el-button>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mt-10">
                            <el-select v-model="user.district" filterable placeholder="Дүүрэг / Сум" style="width: 80%; margin-right: 10px;">
                                <el-option
                                v-for="(item, index) in getDistrict"
                                :key="index"
                                :label="item.districtname"
                                :value="item.id">
                                </el-option>
                            </el-select>
                            <el-button icon="el-icon-plus" @click="modals.district = true" circle></el-button>
                        </el-col>

                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mt-10">
                            <el-input type="textarea" required :rows="4" placeholder="Дэлгэрэнгүй хаяг (60 тэмдэг)" maxlength="60" v-model="user.address"> </el-input>
                        </el-col>

                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mt-10" align="right">
                            <el-button-group>
                                <el-button type="danger" icon="el-icon-arrow-left" @click="$router.push('/kiosks');"> Болих</el-button>
                                <el-button type="primary" native-type="submit" icon="el-icon-check" > Хадгалах</el-button>
                            </el-button-group>
                        </el-col>
                    </el-row>
                </form>
            </div>
        </div>

        <modal :show.sync="modals.city">
            <h6 slot="header" class="modal-title" id="modal-title-default">Хот / Аймаг бүртгэх</h6>
              <div class="row">
                <div class="col-md-12" :style="{'max-height': '400px', 'overflow': 'auto'}">
                    <input type="text" class="form-control mt-1 specInput" v-model="newCity" placeholder="Хотын нэр ж. Улаанбаатар">
                </div>
              </div>

              <template slot="footer">
                  <base-button type="link" class="ml-auto" @click="modals.city = false">Гарах</base-button>
                <base-button type="primary" @click="addCity">Хадгалах</base-button>
          </template>
        </modal> 

        <modal :show.sync="modals.district">
            <h6 slot="header" class="modal-title" id="modal-title-default">Дүүрэг / Сум бүртгэх</h6>
              <div class="row">
                  <div class="col-md-12" :style="{'max-height': '400px', 'overflow': 'auto'}">
                    <el-select v-model="newDistrict.city" filterable placeholder="Хот / Аймаг" style="width: 100%;">
                            <el-option
                            v-for="(item, index) in city"
                            :key="index"
                            :label="item.cityname"
                            :value="item.id">
                            </el-option>
                        </el-select>
                </div>
                <div class="col-md-12" :style="{'max-height': '400px', 'overflow': 'auto'}">
                    <input type="text" class="form-control mt-1 specInput" v-model="newDistrict.district" placeholder="Дүүрэг / Сум нэр ж. Налайх">
                </div>
              </div>

              <template slot="footer">
                  <base-button type="link" class="ml-auto" @click="modals.district = false">Гарах</base-button>
                <base-button type="primary" @click="addDistrict">Хадгалах</base-button>
          </template>
        </modal> 
    </div>
</template>
<script>
  export default {
    data() {
        return {
            user: {
                lastname: '',
                firstname: '',
                email: '',
                phone: '',
                permission: '',
                city: '',
                district: '',
                address: ''
            },
            city: [],
            district: [],
            modals: {
                district: false,
                city: false
            },
            newCity: '',
            newDistrict: {
                city: '',
                district: ''
            },
            loading: false
        }
    },
    computed: {
        getDistrict() {
            var row = [];
            this.district.forEach(el => {
                if(this.user.city == el.cityId) {
                    row.push(el);
                }
            });
            return row;
        }
    },
    mounted() {
      this.getInfo();
    },
    methods: {
        addUser() {
            if(this.user.city == '' || this.user.district == '') {
                this.$message({
                    message: 'Талбараа гүйцэд бөглөнө үү',
                    type: 'error'
                });
            } else {
                var rts = this;
                this.loading = true;
                var token = localStorage.getItem('token');
                rts.$axios({
                    method: 'POST',
                    url: this.$appUrl + '/user/add-new-user',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        user: this.user
                    }
                }).then(data => {
                    rts.loading = false;
                    if(data.data.result == 'success') {
                        this.$notify({
                            title: 'Амжилттай',
                            message: `Бүртгэгдлээ`,
                            type: 'success'
                        });
                    } else {
                        this.$notify({
                            title: 'Амжилтгүй',
                            message: `Аль хэдийн бүртгэлтэй`,
                            type: 'danger'
                        });
                    }
                }).catch(error => {
                    rts.loading = false;
                    console.log(error);
                });
            }
        },
        addDistrict() {
            var rts = this;
            this.loading = true;
            var token = localStorage.getItem('token');
            rts.$axios({
                method: 'POST',
                url: this.$appUrl + '/user/add-new-district',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    city: this.newDistrict.city,
                    district: this.newDistrict.district
                }
            }).then(data => {
                rts.loading = false;
                if(data.data.result == 'success') {
                    this.$notify({
                        title: 'Амжилттай',
                        message: `Бүртгэгдлээ`,
                        type: 'success'
                    });
                    rts.modals.district = false;
                    rts.getInfo();
                } else {
                    this.$notify({
                        title: 'Амжилтгүй',
                        message: `Аль хэдийн бүртгэлтэй`,
                        type: 'danger'
                    });
                }
            }).catch(error => {
				rts.loading = false;
				console.log(error);
			});
        },
        addCity() {
            var rts = this;
            this.loading = true;
            var token = localStorage.getItem('token');
            rts.$axios({
                method: 'POST',
                url: this.$appUrl + '/user/add-new-city',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    city: this.newCity
                }
            }).then(data => {
                rts.loading = false;
                if(data.data.result == 'success') {
                    this.$notify({
                        title: 'Амжилттай',
                        message: `Бүртгэгдлээ`,
                        type: 'success'
                    });
                    rts.modals.city = false;
                    rts.getInfo();
                } else {
                    this.$notify({
                        title: 'Амжилтгүй',
                        message: `Аль хэдийн бүртгэлтэй`,
                        type: 'danger'
                    });
                }
            }).catch(error => {
				rts.loading = false;
				console.log(error);
			});
        },
        getInfo() {
            var rts = this;
            this.loading = true;
            var token = localStorage.getItem('token');
            rts.$axios({
                method: 'POST',
                url: this.$appUrl + '/user/new-kiosk-info',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
            }).then(data => {
                rts.loading = false;
                if(data.data.result == 'success') {
                    rts.city = data.data.city;
                    rts.district = data.data.district;
                }
            }).catch(error => {
				rts.loading = false;
				console.log(error);
			});
        }
    }
  }
</script>
