<style scoped>
.search {
    width: 30%;
}
@media only screen and (max-width: 600px) {
  .search {
    width: 100%;
  }
}

.form__label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  margin-left: 2rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(0rem);
}

.form__input {
  font-family: 'Roboto', sans-serif;
  color: #333;
  font-size: 1.2rem;
	margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}
</style>
<template>
    <div v-loading.fullscreen.lock="loading">

        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8" align="right">
            <el-button type="primary" @click="$router.push('/add-kiosk');" size="mini">Шинэ киоск бүртгэх</el-button>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow border-0 p-5">
                        <div align="right">
                            <form>
                                <el-input placeholder="Киоскын код ж.5" v-model="search" clearable class="search"> </el-input>
                            </form>
                        </div>
                        <div class="table-responsive mt-2">
                                <base-table class="table align-items-center table-flush thead-light"
                                            tbody-classes="list"
                                            :data="filterKiosk">
                                <template slot="columns">
                                    <th :style="{'background': '#F6F9FC'}">#Дугаар</th>
                                    <th :style="{'background': '#F6F9FC'}">Код</th>
                                    <th :style="{'background': '#F6F9FC'}">Хот / Аймаг</th>
                                    <th :style="{'background': '#F6F9FC'}">Дүүрэг / Сум</th>
                                    <th :style="{'background': '#F6F9FC'}">Онлайн</th>
                                    <th :style="{'background': '#F6F9FC', 'text-align': 'right'}">Дэлгэрэнгүй</th>
                                    <th :style="{'background': '#F6F9FC'}"></th>
                                </template>
                                <template slot-scope="{row}">
                                    <th scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                {{row.id}}
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm" style="color: #8898aa;">{{row.code}}</span><br>
                                                <!-- <span class="name mb-0 text-sm">{{row.model}}</span> -->
                                            </div>
                                        </div>
                                    </th>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">{{row.cityname}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">{{row.districtname}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm" v-if="row.live"><span style="color: green;">Онлайн</span></span>
                                                <span class="name mb-0 text-sm" v-else><span style="color: red;">Оффлайн</span></span>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row" align="right">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">{{row.address}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td align="right">
                                        <el-tooltip class="item" effect="dark" content="Контент" placement="top">
                                            <base-button type="primary" @click="$router.push({name: 'Киоск дэлгэрэнгүй', params: { id: row.code }});" size="sm"><span class="ni ni-camera-compact"></span></base-button>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="Устгах" placement="top">
                                            <base-button type="danger" @click="deleteKiosk(row.id)" size="sm"><span class="ni ni-fat-remove"></span></base-button>
                                        </el-tooltip>
                                    </td>
                                </template>
                                </base-table>
                        </div>
                    </div>
                    <!-- <span class="mt-3" @click="getProduct">
                        <base-pagination :page-count="pagination" class="mt-3" v-model="page"></base-pagination>
                    </span> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
    data() {
        return {
            kiosk: [],
            search: '',
            loading: false,
            selected: '',
            checkPhoto: {
                all: false,
                checked: [],
            },
            modals: {
                photo: false,
                video: false
            },
            photos: []
        }
    },
    computed: {
        filterKiosk() {
            var row = [];
            this.kiosk.forEach(el => {
                if(el.cityname.includes(this.search) || el.districtname.includes(this.search) || el.code.includes(this.search)) {
                    row.push(el);
                }
            });
            return row;
        }
    },
    mounted() {
      this.getKiosk();
    },
    methods: {
        deleteKiosk(id) {
            this.$confirm('Устгахдаа итгэлтэй байна уу?', 'Санамж', {
                confirmButtonText: 'Тийм',
                cancelButtonText: 'Болих',
                type: 'warning'
            }).then(() => {
                var rts = this;
                this.loading = true;
                var token = localStorage.getItem('token');
                rts.$axios({
                    method: 'POST',
                    url: this.$appUrl + '/user/delete-kiosk',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        id
                    }
                }).then(data => {
                    rts.loading = false;
                    if(data.data.result == 'success') {
                        rts.kiosk.forEach((el, index) => {
                            if(el.id == id) {
                                rts.kiosk.splice(index, 1);
                            }
                        });
                    }
                }).catch(error => {
                    rts.loading = false;
                    console.log(error);
                });
            });
        },
        getKiosk() {
            var rts = this;
            this.loading = true;
            var token = localStorage.getItem('token');
            rts.$axios({
                method: 'POST',
                url: this.$appUrl + '/user/get-all-kiosk',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
            }).then(data => {
                rts.loading = false;
                if(data.data.result == 'success') {
                    rts.kiosk = data.data.kiosk;
                    rts.photos = data.data.photos;
                }
            }).catch(error => {
				rts.loading = false;
				console.log(error);
			});
        }
    }
  }
</script>
